import React from "react";
import { Link } from "gatsby";

import AltLayout from "../components/alt-layout";
import SEO from "../components/seo";
import SimpleSlider from "../components/slick-slider";
import Section from "../components/Section";
import Suites from "../components/Suites";
import Gallery from "../components/Gallery";
import PropTypes from "prop-types";

const IndexPage = ({ handleClick }) => {
  setTimeout(function() {
    window.location.href = "/"; //will redirect to your blog page (an ex: blog.html)
  }, 2000); //will call the function after 2 secs.
  return (
    <AltLayout>
      <SEO title="Success" />
      <h1 className="center success__message">Thank you for contacting us!</h1>
    </AltLayout>
  );
};

export default IndexPage;
